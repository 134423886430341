import Link from 'next/link';
import { forwardRef } from 'react';
import { Spinner } from './loading';

const COMMON_STYLE = [
  // Text.
  'font-bold',
  'uppercase',
  'py-3',
  'mx-auto',
  'rounded-md',
  'focus:outline-none',
  'focus:shadow-outline',
  'hover:text-white',
  'active:text-white',
  'transition',
  'duration-200',
].join(' ');

export const BUTTON_FILLED_STYLE =
  COMMON_STYLE +
  ' bg-green text-white hover:bg-black hover:border-black active:bg-black';

export const BUTTON_INTENSE_STYLE =
  COMMON_STYLE +
  ' bg-actionRed text-white hover:bg-black hover:border-black active:bg-black';

export const BUTTON_EMPTY_STYLE =
  COMMON_STYLE +
  ' bg-transparent text-green border-green border hover:bg-green hover:border-green active:bg-green';

// eslint-disable-next-line react/display-name
export const Button = forwardRef(
  (
    {
      onClick = null,
      type = '',
      children = null,
      className = '',
      loading = false,
      disabled = false,
      filled = false,
      intense = false,
      wide = false,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      className={`mx-auto flex items-center justify-center
            ${className}
            ${wide ? 'px-14' : 'px-4'} 
            ${
              intense
                ? BUTTON_INTENSE_STYLE
                : filled
                  ? BUTTON_FILLED_STYLE
                  : BUTTON_EMPTY_STYLE
            }

            ${disabled || loading ? 'pointer-events-none opacity-30' : ''}
        `}
      onClick={onClick}
      type={type}
      disabled={loading || disabled}
      {...props}
    >
      {loading && <Spinner className='mr-3' />} {children}
    </button>
  ),
);

export const ButtonLink = ({ href, ...props }) => (
  <Link href={href} legacyBehavior>
    <Button {...props} role='link' />
  </Link>
);

export const LinkButton = ({ className = '', onClick, children }) => (
  <a
    role='button'
    className={`${BUTTON_FILLED_STYLE} ${className} mx-auto mt-8 flex w-full cursor-pointer items-center justify-center px-4`}
    onClick={onClick}
  >
    {children}
  </a>
);
