import { createContext, useContext, useState } from 'react';
import { t } from '../../generated/i18n';
import { logError } from '../../lib/error';
import { Toast } from './toast';

// @ts-ignore
export const ToastContext = createContext();

const SUCCESS = 'success';
const ERROR = 'error';

const initState = {
  text: '',
  type: '',
  actionLabel: null,
  actionLink: null,
  action: null,
  show: false,
  keepAlive: true,
};

export let showToast = () => {};
export let closeToast = () => {};

const setToastOperators = (showFn, closeFN) => {
  showToast = showFn;
  closeToast = closeFN;
};

const ToastProvider = ({ children }) => {
  // @ts-ignore
  const [config, setConfig] = useState(initState);

  const show = (
    text,
    {
      actionLabel = '',
      actionLink = '',
      action = '',
      type = SUCCESS,
      timeout = 5000,
      keepAlive = false,
    },
  ) => {
    setConfig({
      text,
      type,
      action,
      actionLabel,
      actionLink,
      show: true,
      keepAlive,
    });
    if (timeout) {
      setTimeout(() => close(true), timeout);
    }
  };

  const close = (force = false) => {
    // TOODO the config.show is not set on setTimeout. Quickfix: force flag
    if ((!config.keepAlive && config.show) || force) {
      setConfig({ ...initState });
    }
  };

  setToastOperators(show, close);

  return (
    <ToastContext.Provider value={{ toast: { show, close, SUCCESS, ERROR } }}>
      {config.show && <Toast {...config} close={close} />}
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;

export const showCriticalError = (message = '') => {
  logError(`Showing critical error '${message}'.`);
  // @ts-ignore
  showToast(t('genericError'), {
    actionLabel: t('reload'),
    action: () => location.reload(),
    type: ERROR,
    timeout: 8000,
  });
};

export const useToast = () => useContext(ToastContext);
