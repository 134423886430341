import Link from 'next/link';
import { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { t } from '../../generated/i18n';
import { formatPrice } from '../../lib/format';
import { PRODUCT_SMALL_PREVIEW_IMAGE_SIZE } from '../../lib/images';
import { only } from '../../lib/utils';
import { Spinner } from '../common/loading';
import { NumberInput } from '../forms/fields';
import ProductImage from '../product/image';
import { useUser } from '../user-context';
import TotalsSection from './totals-section';

const Line = ({ checkoutLine, ...props }) => {
  const { setQuantityInCart } = useUser();

  const [onDelete, setOnDelete] = useState(false);

  const variant = checkoutLine?.variant;
  const product = variant?.product;

  if (!variant || !product || !product.slug) {
    return null;
  }

  return (
    <li className='border-b border-0.3 pb-6 text-left' {...props}>
      <Link
        href={{
          pathname: '/[category]/[product]',
          query: {
            category: product?.category.slug,
            product: product.slug,
          },
        }}
      >
        <div className='flex flex-row gap-5'>
          <ProductImage
            className='min-w-[35px]'
            product={product}
            size={PRODUCT_SMALL_PREVIEW_IMAGE_SIZE}
            showDiscount={false}
          />

          <div className='mb-2 flex flex-grow flex-col justify-between'>
            <div className='flex items-start justify-between'>
              <div>
                <h3 className='text-base text-black'>{product.name}</h3>
                {variant.name != product.name ? variant.name : ''}

                {product?.metadata?.deliveryDateText ? (
                  <div className='flex text-actionRed'>
                    <img
                      src='/static/clock.svg'
                      alt='clock'
                      width={24}
                      className='mr-1'
                    />
                    <span>{product?.metadata?.deliveryDateText}</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className='min-w-[30px]'>
                {onDelete ? (
                  <Spinner className='ml-3' />
                ) : (
                  <button
                    type='button'
                    className='ml-3 cursor-pointer font-bold'
                    aria-label={t('delete')}
                    onClick={only(() => {
                      setOnDelete(true);
                      setQuantityInCart(product, variant.id, 0);
                    })}
                  >
                    <img src='/static/delete.svg' alt='trash' />
                  </button>
                )}
              </div>
            </div>
            <div className='flex place-items-end content-end justify-between justify-items-end'>
              <NumberInput
                ariaLabelOnly
                useFormik={false}
                className='w-[130px] self-end'
                compact={true}
                label={t('quantity')}
                value={checkoutLine.quantity}
                minValue={1}
                maxValue={variant.quantityAvailable}
                onValueChange={(q) => setQuantityInCart(product, variant.id, q)}
                readOnly={true}
                borderSize={1}
              />
              <div className='place-end ml-2 text-right'>
                {formatPrice(
                  checkoutLine.totalPrice.currency,
                  checkoutLine.totalPrice.gross.amount,
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

const CartLines = ({ className = '', children = null }) => {
  const { checkout } = useUser();

  if (!checkout?.lines) {
    return <>{children(checkout)}</>;
  }

  const deliveryType = checkout.lines.reduce(
    (acc, line) => {
      if (line.variant?.product?.metadata?.deliveryDateText) {
        acc.hasCustomDelivery = true;
      } else {
        acc.hasStandardDelivery = true;
      }
      return acc;
    },
    {
      hasStandardDelivery: false,
      hasCustomDelivery: false,
    },
  );

  return (
    <div className={className}>
      <TransitionGroup className='flex flex-col gap-8' component='ul'>
        {checkout.lines.map((line, i) => (
          <CSSTransition
            key={line?.variant?.id ?? i}
            timeout={200}
            classNames='item'
          >
            <Line
              checkoutLine={line}
              style={{ transition: 'opacity 500ms ease-in-out' }}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
      <TotalsSection className='mt-4' checkout={checkout} />
      {children ? children(checkout, deliveryType) : null}
    </div>
  );
};

export default CartLines;
