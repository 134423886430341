import Image from 'next/legacy/image';
import {
  calcImageProps,
  imgixLoader,
  PRODUCT_PREVIEW_IMAGE_SIZE,
} from '../../lib/images';
import { Discount } from './discount';
import { New } from './new';

const ProductImage = ({
  product,
  size = PRODUCT_PREVIEW_IMAGE_SIZE,
  className = '',
  style = {},
  onClick = null,
  preload = false,
  showDiscount = true,
  align = 'text-center',
}) => {
  let image;

  if (product.images.length > 0) {
    image = product.images[0];
  } else {
    image = {
      height: 1,
      width: 1,
    };
  }

  return (
    <div className={`${className} ${align} relative`} style={style}>
      {image.url && (
        <Image
          className={`${onClick ? 'cursor-pointer' : ''}`}
          onClick={onClick}
          loader={imgixLoader}
          priority={preload}
          {...calcImageProps(image, size)}
        />
      )}
      {showDiscount && product.pricing?.onSale ? (
        <Discount
          pricing={product.pricing}
          className='absolute right-0 top-0'
        />
      ) : showDiscount && product.attributes?.neuheit?.value ? (
        <New className='absolute right-0 top-0' />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProductImage;
