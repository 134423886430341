/* eslint-disable no-undef */

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const GTM_ID = process.env.GTM_ID;
export const ENV = process.env.NODE_ENV;
export const FEATURE_DUMMY_PAYMENT =
  process.env.NEXT_FEATURE_DUMMY_PAYMENT === 'true';

export const CMS_REPOSITORY = process.env.CMS_REPOSITORY;
export const CMS_API_TOKEN = process.env.CMS_TOKEN;
export const CMS_API_URL = `https://${CMS_REPOSITORY}.prismic.io/api/v2`;
export const CMS_GRAPHQL_UL = `https://${CMS_REPOSITORY}.prismic.io/graphql`;

export const API_URI = process.env.API_URI;
export const BASE_URL =
  process.env.BASE_URL ||
  (process.env.VERCEL_URL ? `https://${process.env.VERCEL_URL}` : undefined);
export const STATIC_BUILD = process.env.STATIC_BUILD ? true : false;

export const VAT = 0.077;

export const START_PAGE_UID = 'home';

export const GRAPHQL_API = `${API_URI}/graphql/`;

export const REVALIDATE_SECONDS = false; // 60 * 10; // 10 minutes

export const DATATRANS_FINGER_PRINT_HANDLER =
  'https://h.online-metrix.net/fp/tags.js?org_id=lq866c5i&session_id=';
