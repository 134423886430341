import Link from 'next/link';

const BUTTON_LINK =
  'cursor-pointer uppercase px-6 py-1 border border-white rounded-full ml-1';

export const Toast = ({
  text,
  type,
  actionLabel,
  actionLink,
  action,
  close = null,
}) => {
  const bgClassName = type == 'error' ? 'bg-red1 text-center' : 'bg-green';

  return (
    <div
      role='alert'
      onClick={!actionLink && !action && close ? close : undefined}
      className={`${bgClassName} shadow-t-lg fixed bottom-16 left-0 right-0 z-50 mx-auto max-w-full rounded px-4 py-3 font-bold text-white sm:px-6 md:bottom-auto md:top-24 md:max-w-md md:shadow-lg lg:px-8`}
    >
      <div className='flex items-center'>
        <p className='flex-grow'>{text}</p>
        {actionLink ? (
          <Link href={actionLink} className={BUTTON_LINK}>
            {actionLabel}
          </Link>
        ) : (
          action && (
            <a onClick={action} className={BUTTON_LINK}>
              {actionLabel}
            </a>
          )
        )}
        {close && !actionLink && !action && (
          <a
            role='button'
            onClick={close}
            className='float-right cursor-pointer text-xl'
            aria-label='Schliessen'
          >
            ×
          </a>
        )}
      </div>
    </div>
  );
};
