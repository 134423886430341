import * as Sentry from '@sentry/nextjs';
import 'isomorphic-unfetch';
import { API_URI } from './config';
import { ON_CLIENT } from './utils';

const DEFAULT_HEADERS = ON_CLIENT
  ? { 'Content-Type': 'application/json' }
  : {
      'Content-Type': 'application/json',
      // eslint-disable-next-line no-undef
      'Api-Authorization': process.env.API_AUTHORIZATION_TOKEN,
    };

export const fetchJson = async (url, options = {}) => {
  let text = '';
  try {
    options.headers = {
      ...DEFAULT_HEADERS,
      ...(options.headers || {}),
    };
    const res = await fetch(url, options);
    text = await res.text();
    return JSON.parse(text);
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setExtras({ text, url });
      Sentry.captureException(error);
    });
    console.error(error);
    return { errors: { error: `${error}` }, hasErrors: true };
  }
};

const SEARCH_API = ON_CLIENT ? `/api/search` : `${API_URI}/search/`;

export const search = async (params = '') => {
  return await fetchJson(`${SEARCH_API}?${params}`);
};

export const executeGQL = async (operationName, variables = {}) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      operationName,
      variables,
    }),
  };

  return await fetchJson('/api/graphql-operation', options);
};
